/* eslint-disable @typescript-eslint/ban-ts-comment */
// @ts-nocheck

function removeTrailingSlash(str: string) {
	if (str.endsWith('/')) {
		// 如果字符串以斜杠结尾，使用 slice 方法去除最后一个字符

		return str.slice(0, -1)
	}

	// 否则，返回原字符串

	return str
}
const APP_DOMAIN = 'elittleworld_extension_data' as string
export const snake = window?.[APP_DOMAIN]?.env?.SNAKE || 'power_shop'
export const appName = window?.[APP_DOMAIN]?.env?.APP_NAME || 'Power Shop'
export const kebab = window?.[APP_DOMAIN]?.env?.KEBAB || 'power-shop'
export const app1Selector = window?.[APP_DOMAIN]?.env?.APP1_SELECTOR || ''
export const app2Selector = window?.[APP_DOMAIN]?.env?.APP2_SELECTOR || ''
export const app3Selector = window?.[APP_DOMAIN]?.env?.APP3_SELECTOR || ''
export const apiUrl = window?.wpApiSettings?.root || '/wp-json'
export const ajaxUrl =
	window?.[APP_DOMAIN]?.env?.ajaxUrl || '/wp-admin/admin-ajax.php'
export const siteUrl = window?.[APP_DOMAIN]?.env?.siteUrl || '/'
export const currentUserId = window?.[APP_DOMAIN]?.env?.userId || '0'
export const postId = window?.[APP_DOMAIN]?.env?.postId || '0'
export const permalink = window?.[APP_DOMAIN]?.env?.permalink || '/'
export const apiTimeout = '30000'
export const ajaxNonce = window?.[APP_DOMAIN]?.env?.nonce || ''
